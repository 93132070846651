.slide-select, .toggle {
  display: inline-block;
  &.state{
    .pillSlider {
      width:  77px;
      height: 22px;
    }
    .pill {
      width:  40px;
      height: 22px;
    }
  }
  .pillSlider {
    width:  160px;
    height: 22px;
  }
  .pill {
    width:  59px;
    height: 22px;
  }
  .controlName {
    font-weight: 600;
    display: inline-block;
    margin: 4px 15px 0px 0px;
    vertical-align: top;
    text-transform: capitalize;
  }
}
.pillSlider {
  border-radius:  15px;
  background:     $primaryBackgroundColor;
  position:       relative;
  cursor:         pointer;
  display:        inline-block;
  vertical-align: text-top;
  .states{
    position: absolute;
    span{
      display: inline-block;
      margin: 3px 8px 0px 12px;
      text-transform: capitalize;
      font-size: 0.9rem;
      font-weight: 300;
      color:          $disabledColor;
      &.active{
        color: $primaryUltraDarkColor;
      }
    }
  }
  .pill{
    z-index:       0;
    border-radius: 15px;
    background:    $secondaryDarkColor;
    padding:       2px 0;
    box-sizing:    border-box;
    position:      absolute;
    top:           0;
    left:          0;
    transition:    all .25s ease-out;
  }
  .pill.disabled {
    background: #c2c5c0;
  }
}

.pillSlider.transitioning {
  background: linear-gradient(45deg, #ecebeb, #ccffc3);
  background-size: 400% 400%;
  -webkit-animation: PillGradient 2s ease infinite;
  -moz-animation: PillGradient 2s ease infinite;
  animation: PillGradient 2s ease infinite;
}

@-moz-keyframes PillGradient {
  0% {
    background-position: 0% 50%
  }
  50% {
    background-position: 100% 50%
  }
  100% {
    background-position: 0% 50%
  }
}

@keyframes PillGradient {
  0% {
    background-position: 0% 50%
  }
  50% {
    background-position: 100% 50%
  }
  100% {
    background-position: 0% 50%
  }
}

@-webkit-keyframes PillGradient {
  0% {
    background-position: 0% 50%
  }
  50% {
    background-position: 100% 50%
  }
  100% {
    background-position: 0% 50%
  }
}
