.deductible-guarantee {

  h2 {
    font-weight: 200;
    margin-bottom: 1rem !important;
  }
  .subtitle {
    a {
      color: inherit;
      text-decoration: underline;
    }
  }

  .card {
    padding: 1.25rem;

    .status{
      padding: 0px 0 14px 0;

      .status-badge{
        font-size: 95%;
        background-color: #D73333;
        padding-right: 9px;
        padding-left: 9px;
        padding-top: 5px;
      }

      .status-badge.badge-on {
        background-color: #89CF64;
        color: white;
      }

      .status-badge.badge-warning {
        background-color: #FFB141;
        color: white;
      }

      .status-title {
        font-weight: 600;
        display: inline-block;
        margin: 2px 15px 0px 0px;
        vertical-align: top;
        text-transform: uppercase;
      }
    }

    .inner-card {
      background-color: #48647F;
      padding: 36px;
      color: white;

      .title {
        font-size: 19px;
        margin: 0 0 15px 0;
        font-weight: 600;
      }
      .description {
        font-size: 16px;
        margin: 0 0 15px 0;
        font-weight: 300;
      }
      .flo-protect-link {
        font-weight: 400;
        text-transform: uppercase;

        a {
          color: #60D096;
        }
      }
    }

    .inner-card.subscribed-alerts {
      .title {
        font-weight: 400;
        overflow: auto;
        margin: 0 0 12px 0;

        .icon {
          float: left;
          margin-right: 15px;
  
          .border {
            stroke: #abe9d2;
          }
  
          .checkmark {
            stroke: #24b47e;
          }
        }
        .label {
          margin-top: 8px;
          font-weight: 300;
          font-size: 20px;
        }
        .label.with-alerts {
          margin-top: 0px;
        }

        .alerts {
          float: left;
          margin-right: 15px;

          .alerts-count {
            padding: 5px 10px;
            font-size: 19px;
            font-weight: 400;
            line-height: 1;
            color: #FFF;
          }

          .warning, .info {
            background-color: #FFB142 !important;
          }
          .critical {
            background-color: #D91C1D !important;
          }
        }
      }
      .see-all {
        font-weight: 300;
        a {
          color: inherit;
          text-decoration: underline;
        }
      }

      .view-alerts {
        margin-top: 28px;

        .alerts-button {
          font-size: 17px;
          font-weight: 300;
          line-height: 2;
          padding: 11px 46px;
          background-color: #A3B2BF;
        }
      }
    }
  }
}