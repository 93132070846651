.add-shutoff-device-banner {
  background: url('/assets/images/upsell_shutoff_banner_bg_px.png');
  background-color: #A8C3CF;
  margin-bottom: 14px;
  width: 100%;

  .background-box {
    background: url('/assets/images/upsell_shutoff_banner_bg.png');
    background-repeat: no-repeat;
    min-height: 140px;
    width: 100%;

    .header-section {
      height: 30px;

      .close-icon-wrapper {
        float: right;
        margin: 3px 3px 0 0;
        cursor: pointer;

        img {
          height: 34px;
        }
      }
    }

    .content-section {
      margin: 0px 10px 10px 180px;

      .content-wrapper {
        margin: 0;
        padding: 0;

        .shutoff-text {
          margin: 13px 0 0 0;
          padding-left: 30px;
          color: white;
          font-size: 19px;
        }

        @include media-breakpoint-only(lg) { .shutoff-text { padding-left: 42px; } }
        @include media-breakpoint-only(sm) { .shutoff-text { padding-left: 66px; font-size: 14px; } }
        @include media-breakpoint-only(xs) { .shutoff-text { padding-left: 66px; font-size: 13px; } }

        .drop-icon {
          img {
            height: 100px;
          }
        }
      }

      .button-wrapper {
        margin: 16px 0 0 0;

        .add-shutoff-button {
          font-size: 17px;
          font-weight: 300;
          line-height: 2;
          padding: 8px 40px;
          color: #5C6E85;
          background-color: #ffffff;
        }

        @include media-breakpoint-only(lg) { .add-shutoff-button { font-size: 14px; padding: 8px 25px; } }
        @include media-breakpoint-only(xs) { .add-shutoff-button { font-size: 14px } }
      }

      @include media-breakpoint-down(md) { .button-wrapper { margin: 4px 0 12px 0; text-align: center; } }
    }
  }

  @include media-breakpoint-only(xl) { .background-box { background-size: 100% } }
  @include media-breakpoint-only(lg) { .background-box { background-size: 1100px } }
  @include media-breakpoint-only(md) { .background-box { background-size: 1542px; background-position-x: -28px; } }
  @include media-breakpoint-only(sm) { .background-box { background-size: 1600px; background-position-x: -58px; } }
  @include media-breakpoint-only(xs) { .background-box { background-size: 1544px; background-position-x: -58px; } }
}