////////////////////////////////////////////////////////////////////////////////////////////////////
// This should be loaded before any import or other styles to avoid flickering of fonts when page
// loads
html {
  min-height: 100%;
  position: relative;
  font-family: "Questrial";
  font-weight: 300;
}

////////////////////////////////////////////////////////////////////////////////////////////////////

// Import Fonts
@import "font/fonts";

// Import Bootstrap ( first so anything else we do over writes )
@import "bootstrap/bootstrap.scss";

@import "variables.scss";

$flo-font-color: #19476D;

$flo-blue: #1c486c;

$flo-dark-blue: #194161;

$flo-darker-blue: #153651;

$flo-darkest-blue: #122d44;

$flo-light-blue: #3c729e;

$flo-green: #73d352;

$flo-dark-green: #64be4c;

$flo-darker-green: #54a442;

$flo-darkest-green: #54a442;

$flo-gray: #e8e7e7;

$border-color: #EBEBEB;

$flo-red: #D73333;

.card-body {
  margin-left: 20px;
  margin-right: 20px;
}

@import '../../node_modules/rc-slider/assets/index';

@import "layout/footer.scss";

@import "elements/dialog.scss";

@import "elements/flo-group-togglebox.scss";

@import "elements/pagination.scss";

@import "elements/telemetry-widget.scss";

@import "elements/logo.scss";

@import "elements/toggle-and-slide-select.scss";

@import "elements/places-search-bar.scss";

@import "elements/data-download.scss";

@import "elements/skeleton.scss";

@import "views/login.scss";

@import "views/alerts.scss";

@import "views/home-protect.scss";

@import "views/settings-notifications.scss";

@import "views/flo-detect-validation.scss";

@import "views/account-creation-landing.scss";

@import "views/authorization.scss";

@import "views/email-unsubscribed.scss";

@import "views/insurance-letter.scss";

@import "views/deductible-guarantee.scss";

@import "layout/location-selector.scss";

@import "elements/detector-telemetry-widget.scss";

@import "elements/add-shutoff-device-banner.scss";

@import "views/email-confirmation-change.scss";

@import "views/account-confirmation.scss";

html, body {
  background-color: #f8fafe;
  font-size: 14px;
}

main {
  margin: 20px 0;
  color: $flo-font-color;
}

#app {
  margin-bottom: 11rem;
}

.btn {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.lighter-font {
  font-weight: 200;
}

.flo-light-blue {
  color: #fff;
  background-color: $flo-light-blue!important;
}

.flo-red {
  color: #fff;
  background-color: $flo-red!important;
}

.data-downloader {
  .floprotect-locked-message {
    color: #2B587A;
    max-width: 120px;
    font-size: 11px;
    margin: 0px 10px 5px 10px;
    line-height: 1.3;
  }

  @media (min-width: 992px) {
    .floprotect-locked-message {
      display: none;
    }
  }

  .dropdown-menu.show {
    padding: 0px;

    button {
      padding: 0px;

      a {
        height: 100%;
        width: 100%;
        padding: 7px 21px;
        display: block;
        color: black
      }
    }
  }
}

@media (max-width: 992px) {
  .data-downloader-popover {
    display: none;
  }
}

.select-with-fas {
  font-family: FontAwesome, sans-serif;
}

.flo-blue {
  color: #fff;
  background-color: $flo-blue!important;
}

.flo-green {
  color: #fff;
  background-color: $flo-green!important;
}

.flo-yellow {
  color: #fff;
  background-color: $warningColor!important;
}

.flo-blue-color {
  color: $flo-blue;
}

.flo-green-color {
  color: $flo-green;
}

.flo-light-blue-color {
  color: $flo-light-blue;
}

div.b {
  display: inline;
  white-space: nowrap;
  width: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  border: 1px solid #000000;
}

.truncate-text {
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.navbar {
  text-transform: uppercase;
  background-color: $flo-blue;
  padding: 0px 10%;

  .address {
    color: #ffffff;
    font-weight: 300;
    text-transform: none;
    display: none;
  }

  .address.nav-link {
    padding: 15px 0px;
  }

  @media (min-width: 1200px) {
    .address {
      width: 210px;
      padding-top: 5px;
      display: inline-block;
    }

    .address.nav-link {
      display: none;
    }
  }

  @media (min-width: 1250px) {
    .address {
      width: 270px;
    }
  }

  @media (min-width: 1350px) {
    .address {
      width: 370px;
    }
  }

  @media (max-width: 767px) {
    .address.nav-link {
      display: block;
    }
  }

  .badge {
    padding: 5px 10px;
    margin-left: 5px;
  }

  .navbar-toggler {
    border: none;
  }

  .fa-bars {
    font-size: 1.3em;
    color: #fff;
  }

  button:active,
  button:focus,
  button:hover{
    border:none !important;
    outline:none !important;

    .fa-bars {
      color: $flo-gray;
    }
  }

  .nav-link {
    color: #fff!important;
  }

  .nav-item:not(.active) {
    a.nav-link:hover {
      color: #73d352!important;
    }
  }

  .navbar-brand
  {
    margin-right: 30px;
  }

  .main-navbar {
    margin-top: -10px;

    li {
      padding-top: 10px;
    }
  }

  .sub-menu {
    .dropdown-item a {
      text-transform: capitalize;
      color: $flo-blue!important;
    }

    button:active {
      background-color: $flo-gray;
    }
  }

  .alert-notification {
    .badge {
      padding: 8px 15px;
      font-size: 1em;

      .badge {
        color: $flo-blue;
        background-color: #fff;
      }
    }
    margin: 0px 20px;
  }

  .alert-notification .device-online {
    background-color: $flo-green;
  }

  .alert-notification .device-offline {
    background-color: $errorColor;
  }

  .alert-notification .no-device {
    color: $flo-blue;
    background-color: $flo-gray;
  }

  .alert-notification .has-alerts {
    background-color: $warningColor!important;
    padding: 5px 10px 5px 5px;

    .badge {
      padding: 4px 8px!important;
    }
  }
}

@include media-breakpoint-up(md) {
  .navbar {
    .nav-item.active {
      border-top: 5px solid $flo-green;
      margin-top: -9px;
      padding-top: 4px;
    }
  }
}

@include media-breakpoint-down(sm) {
  .navbar {
    .dropdown-menu {
      border: none;
      background-color: $flo-blue;
    }

    .nav-item.active {
      border-left: 5px solid $flo-green;
      margin-left: -12.5%;
      padding-left: 11.5%;
    }
  }

  .navbar .sub-menu .dropdown-item a {
    color: #fff!important;
    padding: 0px;
  }

  .navbar .sub-menu .dropdown-item:hover {
    background-color: $flo-blue;
  }

  .navbar .settings {
    margin-bottom: 20px;
  }
}

.card {
  border-color: $border-color;
}

.card-header {
  margin-bottom: 0px;
  border-bottom: none;
  padding-top: 15px;
  padding-bottom: 0px;
  background-color: #fff;
}

.card-header:after {
  content: "";
  display: block;
  height: 1px;
  width: 100%;
  background: $border-color;
  margin-top: 0.75rem;
}

.card-footer {
  border: none;
  background-color: #fff;
}

.progress {
  height: 1.5rem;
  border-radius: 0;
}

.bottom-margin {
  margin-bottom: 20px;
}

.btn-green-border {
  color: $flo-font-color;
  background-color: #fff;
  border: 2px solid $flo-green;
  text-transform: uppercase;
}

.btn-green-border:hover {
  font-weight: 600;
  border-width: 3px;
  border-style: double;
}

.btn-success, .btn-success:disabled {
  color: $flo-green;
  background-color: #fff;
  border: 2px solid $flo-green;
  text-transform: uppercase;
}

.btn-warning, .btn-warning:disabled {
  color: $warningDarkColor;
  background-color: #fff;
  border: 2px solid $warningDarkColor;
  text-transform: uppercase;
}

.btn-error, .btn-error:disabled {
  color: $errorColor;
  background-color: #fff;
  border: 2px solid $errorColor;
  text-transform: uppercase;
}

.healthcheck-widget {
  button.btn-danger {
    margin-top: 10px;
  }
}

.home-protect.card {
  color: #fff;
  background-color: $flo-blue;
  font-weight: 300;

  p.name {
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
  }

  a {
    color: $flo-green;
  }

  .badge {
    text-transform: uppercase;
    font-size: 1em;
    padding: 6px 18px;
    background-color: $gray-500;
    margin-left: 15px;
  }

  .fa-shield-alt {
    font-size: 1.7em;
    margin-right: 5px;
  }
}


select {
  background: none;
}

$nav-pills-color: #586880;

.nav-pills .nav-link {
  color: $nav-pills-color;
  cursor: pointer;
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  background-color: $nav-pills-color;
  color: #fff;
}

.nav-pills .nav-link.active:hover {
  color: #fff;
}

.nav-pills.nav-inner-view {
  margin-bottom: 20px;
}


.btn-primary, .btn-primary:disabled, .btn-primary.disabled  {
  background-color: $flo-green;
  border-color: $flo-green;
}

.btn-primary:hover {
  background-color: $flo-dark-green;
  border-color: $flo-dark-green;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  background-color: $flo-darkest-green;
  border-color: $flo-darkest-green;
}

.btn-secondary, .btn-secondary:disabled, .btn-secondary.disabled {
  background-color: $flo-blue;
  border-color: $flo-blue;
  color: #fff;
}

.btn-secondary:hover {
  background-color: $flo-dark-blue;
  border-color: $flo-dark-blue;
}

.btn-outline-secondary {
  @extend .btn-secondary;

  background-color: #fff;
  color: $flo-blue;
}

.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  background-color: $flo-darkest-blue;
  border-color: $flo-darkest-blue;
}


btn:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

$current-period-color: #4292BE;
$previous-period-color: #2b5876;

.usage {
  .period {
    font-weight: 600;
  }

  .current-period {
    color: #4292BE;
  }

  .previous-period {
    color: #2b5876;
  }
}


.usage .vital-measurements {
  [class*="-graph"]{
    .pressure,
    .temperature,
    .flow {
      text-align: 		center;
      width:      		116px;
      display: 				inline-block;
      vertical-align: top;
      .icon-PSI-Gauge:before{
        font-size: 2.8rem;
        color: $flo-green;
      }
      .icon-Thermometer:before, .icon-Faucet:before{
        font-size: 3.8rem;
        color: $flo-green;
      }
      .title{
        font-weight: 		600;
        color:       		$primaryDarkColor;
        font-size:   		1rem;
        line-height:		1rem;
        text-transform: uppercase;
        margin-top:			8px;
      }
      .unit {
        color:     	 $primaryLightColor;
        font-size: 	 0.8rem;
        line-height: 1rem;
      }
    }
  }

  .device-dropdown {
    margin-bottom: 10px;
  }
}

.usage-content-wrapper {
  margin-top: 30px;
}

.usage-wrapper {
  border-bottom: #ccc solid 1px;
}

.settings-contacts {
  .column.actions {
    font-weight: 600;
    cursor: pointer;
  }

  .table {
    .form-group {
      margin-bottom: 0rem;
    }
  }
}

.table thead th {
  border-top: none;
  font-weight: 600;
  text-transform: uppercase;
}

.table th, .table td {
  vertical-align: text-top;
}

.hidden {
  display: none;
}

.buttons {
  .btn {
    margin-right: 10px;
  }
}


//Usage

.usage {
  .nav-pills {
    margin-bottom: 0px;
  }

  .usage-period {
    background-color: white;
    margin-top: 7px;
  }

  .usage-period-xs {
    background-color: white;
    margin-bottom: 15px;
  }
}


// Forms
.form {
  .message-container {
    .error-message {
      color:          $errorColor;
    }
  }

  input.invalid{
    color: $errorColor;
    border-color: $errorColor;
  }

  textarea.invalid{
    color: $errorColor;
    border-color: $errorColor;
  }

  input.invalid::placeholder {
    color: $errorColor;
  }
}

.link-unstyled, .link-unstyled:link, .link-unstyled:hover {
  color: inherit;
  text-decoration: inherit;
}

.progress-bar-left-aligned {
  text-align: left;
  padding-left: 15px;
}

.progress-bg {
  height: 2.1rem;
  font-size: 1rem;
  font-weight: 300;
}

.progress-bar-delimiter {
  border-left: 1px solid white;
}

.card.daily-usage-goal {
  .gallons-consumed-vs-goal-total {
    .gallons-consumed {
      font-size: 2rem;
    }
    .goal-total {
      color: #949494;
      font-size: 1.4rem;
      font-weight: 400;
    }
  }
}

.leak-detection-widget.health-test {
  .popover-info-icon {
    color: $gray-400;
    margin: 0 5px;
  }
  .healthcheck-widget {
    min-height: 38px;
  }
  .sensitivity-controls-container {
    height: 50px;

    .drip-logo-container {
      padding: 0px;
    }

    .rc-slider {
      width: 80%;
    }
  }
}

.no-device-stats-card {
  padding: 1.25rem;
  h2 {
    font-size: 1.4rem;
  }
  .subtitle {
    font-size: 1.1rem;
  }
  a {
    padding: 30px 15px 10px 0px;

    img {
      width: 140px;
    }
  }
}

.device-stats-card {
  .telemetry-controls {
    min-height: 80px;
    margin: 24px 15px 44px 0;
    background-color: #F7F9FE;
    padding: 8px;
  }

  @include media-breakpoint-up(lg) {
    .device-state-container {
      border-left: solid $border-color 1px;
      padding-right: 0;
    }
  }

  @include media-breakpoint-down(md) {
    .device-state-container {
      border-top: solid $border-color 1px;
      margin-top: 22px;
      padding-top: 14px;
    }
  }

  .device-dropdown {
    text-align: left;
    .dropdown-toggle {
      border-radius: 0;
      background-color: #505D73;
    }
  }

  .device-status {
    text-align: left;
    padding: 8px 0 0 20px;

    .device-status-icon {
      padding: 5px 6px;
      margin-top: 5px;
      font-size: 22px;
      font-weight: 400;
      line-height: 1;
      height: 12px;
      width: 12px;
      display: block;
      float: left;
    }

    .device-status-icon.device-online {
      background-color: $flo-green;
    }
    .device-status-icon.device-offline {
      background-color: #D73333;
    }

    .status-text {
      padding: 2px 0px 0 8px;
      display: block;
      float: left;
      text-transform: capitalize;
    }
  }

  .last-reported-date {
    text-align: right;
    margin-top: 14px;
  }

  .loader-wrapper {
    min-height: 190px;
    padding-top: 40px;

    .loader {
      border: 5px solid #f3f3f3;
      border-radius: 50%;
      border-top: 5px solid #3498db;
      width: 80px;
      height: 80px;
      margin: auto;
      -webkit-animation: spin 2s linear infinite; /* Safari */
      animation: spin 2s linear infinite;
    }
  }
}

.rc-slider-handle {
  height: 25px;
  width: 25px;
  border-radius: 25px;
  margin-left: -11px;
  margin-top: -11px;
  border: 1px #ccc;

  -webkit-box-shadow: 0px 1px 2px 0px rgba(171,171,171,1);
  -moz-box-shadow: 0px 1px 2px 0px rgba(171,171,171,1);
  box-shadow: 0px 1px 2px 0px rgba(171,171,171,1);
}

.rc-slider-dot.rc-slider-dot-active {
  border-color: $flo-green;
}

.rc-slider-track {
  background-color: $flo-green;
}

.rc-slider-handle:active {
  border-color: $flo-green;
  box-shadow: 0 0 5px $flo-green;
}



@for $i from 1 through 10 {
  $size: 10 *$i;

  .image-size-#{$size} {
    width: $size#{'%'};
  }

  .image-size-#{$size + 5} {
    width: $size+5#{'%'};
  }
}

$fixture-appliance-color: #1EB6CB;
$fixture-bath-color: #E92C90;
$fixture-shower-color: #E93A2E;
$fixture-faucet-color: #A343B4;
$fixture-toilet-color: #F2A647;
$fixture-drip-irrigation-color: #4E85BB;
$fixture-hose-color: #2E9AE9;
$fixture-pool-color: #34F0CF;
$fixture-hot-tub-color: #34F0CF;
$fixture-irrigation-color: #4395AF;
$fixture-multiple-fixtures-color: #EA552F;
$fixture-water-softner-color: #50BA5B;
$fixture-water-filter-color: #50BA5B;
$fixture-other-color: #9EBED0;

.fixture-400 {
  background-color: $fixture-appliance-color;
}

.fixture-102 {
  background-color: $fixture-bath-color;
}

.fixture-101 {
  background-color: $fixture-shower-color;
}

.fixture-300 {
  background-color: $fixture-faucet-color;
}

.fixture-200 {
  background-color: $fixture-toilet-color;
}

.fixture-501 {
  background-color: $fixture-drip-irrigation-color;
}

.fixture-701 {
  background-color: $fixture-hose-color;
}

.fixture-601 {
  background-color: $fixture-pool-color;
}

.fixture-602 {
  background-color: $fixture-hot-tub-color;
}

.fixture-500 {
  background-color: $fixture-irrigation-color;
}

.fixture-900 {
  background-color: $fixture-multiple-fixtures-color;
}

.fixture-801 {
  background-color: $fixture-water-softner-color;
}

.fixture-802 {
  background-color: $fixture-water-filter-color;
}

.fixture-10000 {
  background-color: $fixture-other-color;
}

.card.fixture-usage-summary {
  .fixture-details {
    margin: 15px 0px;

    .fixture-logo-container {
      height: 45px;
      width: 45px;
      border-radius: 80px;
      color: #fff;
    }

    .fixture-logo-container.disabled {
      background-color: rgba(45, 45, 45, 0.25) !important;

      img {
        opacity: 0.2;
      }
    }

    .fixture-usage {
      margin-top: 1px;
      line-height: 1.2;
      margin-top: 5px;
      color: #77869B;
    }
    .fixture-name {
      line-height: 1.2;
      margin-top: 5px;
    }

    .fixture-text {
      margin-left: 15px!important;;
    }

    .fixture-logo-container.active {
      box-shadow: 1px 4px 3px 2px rgb(224, 224, 224);
      -webkit-box-shadow: 1px 4px 3px 2px rgba(224,224,224,1);
      -moz-box-shadow: 1px 4px 3px 2px rgba(224,224,224,1);
    }
  }
}

.card.fixture-usage-details {

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
    height: 8px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: #9B9B9B;
    -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
  }

  .timeline {
    height: 400px;
    overflow-y: scroll;
    overflow-x: hidden;

    .timeline-mark {
      font-size: 8px;
      width: 14px;
      height: 14px;
    }

    .timeline-line {
      margin-bottom: -6px;
    }
    
    .show-more {
      display: flex;

      a {
        width: 100%;
        text-align: right;
        margin-right: 20px;
      }
    }

    .loader-wrapper {
      display: flex;

      .loader {
        border: 3px solid #f3f3f3;
        border-radius: 50%;
        border-top: 3px solid #3498db;
        width: 20px;
        height: 20px;
        margin: auto;
        -webkit-animation: spin 2s linear infinite; /* Safari */
        animation: spin 2s linear infinite;
      }
    }
  }

  .tiny-height{
    line-height: 1.2;
    margin-top: 4px;
  }

  @include media-breakpoint-down(md) {
    .timeline {
      height: 650px;
    }
  }

  .banner {
    h3 {
      font-size: 2.5rem;
      font-weight: 300;
    }

    img {
      margin: 15px;
    }

    a {
      color: #73d352;
      font-size: 1.2rem;
      font-weight: 300;
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}

.echarts-for-react {
  div {
    position: absolute !important;
  }
}

/// TEMPORARY
.dot-wave {
  .dot {
    display:inline-block;
    width:10px;
    height:10px;
    border-radius:50%;
    margin-right:8px;
    background: #898989;
    animation: wave 1.3s linear infinite;

    &:nth-child(2) {
      animation-delay: -1.1s;
    }

    &:nth-child(3) {
      animation-delay: -0.9s;
    }
  }
}

@keyframes wave {
  0%, 60%, 100% {
    transform: initial;
  }

  30% {
    transform: translateY(-10px);
  }
}


/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}