.flo-detect{

  .alert {
    width: 100%
  }
  .select-device-container {
    margin-bottom: 1rem;
    padding: {
      top: 15px;
      bottom: 15px;
    }

    th, td {
      @extend .text-center
    }

    .fixture-type-column {
      width: 200px;
    }

    .select-device-label {

      margin: {
        right: 10px;
      }
    }

    .looks-good-column {

      .toggle-container {
        text-align: center;

        .feedback-dropdown-container {
          margin-top: 10px
        }
      }
    }
  }

  .submit-button-container {
    text-align: center
  }



}
