.account-creation-landing {
  background-color: #fff;

  .main-container {
    padding-bottom: 250px;
  }

  .multi-step-indicator {
    margin: 30px;
  }

  .buttons {
    .btn-landing {
      margin: 0px 5px;
    }
  }

  .address {
    width: 305px;
    font-size: 24px;
    font-weight: bold;
    line-height: 30px;
    margin: 25px auto 10px auto;
  }

  .confirmation-question {
    font-size: 16px;
    line-height: 19px;
    color: #848080;
    font-weight: 300;
  }

  .header-logo {
    margin: 0px 20px;
    width: 55px;
  }

  .header {
    padding: 20px 0;
  }

  .header-text {
    color: #77869B;
    font-weight: 300;
  }

  .jumbotron {
    background: url("/assets/images/background.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    margin-bottom: 0rem;

    h1 {
      color: #333333;
      font-family: "PT Sans";
      font-size: 30px;
      font-weight: bold;
      letter-spacing: 0.3px;
      line-height: 28px;
    }

    .short-description {
      max-width: 588px;
      color: #333333;
      font-size: 18px;
      letter-spacing: 0.1px;
      line-height: 24px;
      margin: 15px 0px;
    }

    .form-places-search {
      @media (min-width: 576px) {
        margin: 30px 0px 80px 0px;

        .form-group-search {
          margin-right: 10px;
          width: 35%;
        }
      }

      @media (max-width: 575px) {
        margin: 30px 0px 30px 0px;
        display: block;

        .form-group-search {
          margin: 0 auto 20px;
          width: 80%;
        }
      }

      .search-bar-component {
        input {
          border: 1px solid #333333;
          box-shadow: none;
          padding: 10px 10px 10px 40px;

          // Add marker icon
          background-image: url(/assets/images/marker@2x.png);
          background-position: 12px 14px;
          background-repeat: no-repeat;
          background-size: 14px;
        }

        input::placeholder {
          color: #BBBBBB;
          font-family: "PT Sans";
          font-size: 15px;
          line-height: 19px;
          font-weight: 300;
        }

        .clear-button {
          display: none;
        }
      }

      @media (max-width: 575px) {
        .search-bar-component {
          margin-bottom: 45px;

          .error-message {
            max-width: 95%;
            margin-left: -10%;
          }
        }
      }

      button {
        padding: 12px;
        border-radius: 4px;
        background-color: #1DD491;
        color: #FFFFFF;
        font-family: "PT Sans";
        font-size: 15px;
      }
    }

    .press-logos {
      width: 70%;
    }
  }

  .light-bulb {
    width: 22px;
    margin: -5px 10px 0px 10px;
  }

  .did-you-know {
    padding: 10px;
    font-weight: 300;
    color: #77869B;
    background-color: #F8FAFE;
  }

  .how-it-works {
    @media (min-width: 576px) {
      margin: 0px 40px 40px;
    }

    h2 {
      color: #77869B;
      font-size: 1.35rem;
      font-weight: bold;
      letter-spacing: 1.25px;
      margin: 25px 0px;
    }

    .step {
      border-radius: 50%;
      max-width: 36px;
      height: 36px;
      padding: 2px;
      color: #fff;
      text-align: center;
      font-size: 1.4rem;
      font-weight: 300;
      background-color: #19476D;
      margin: 0 auto;
    }

    .step-description {
      color: #333333;
      font-size: 16px;
      line-height: 20px;
      margin: 25px 0px 35px 0px;
    }

    .how-it-works-note {
      max-width: 580px;
      color: #BBBBBB;
      font-size: 11px;
      letter-spacing: 0.06px;
      line-height: 13px;
      font-weight: 300;
      margin: 0 auto;
    }
  }

  .home-protect-features {
    background-color: #F8FAFE;
    padding-top: 40px;

    @media (min-width: 576px) {
      margin: 40px;
      padding-bottom: 70px;
    }

    @media (max-width: 575px) {
      margin: 20px 0px 0px 0px;
      padding-bottom: 0px;
    }

    h2 {
      color: #333333;
      font-family: "PT Sans";
      font-size: 18px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0px;
    };

    .shield {
      width: 22px;
      margin-top: -5px;
    }

    h3 {
      color: #000000;
      font-family: "PT Sans";
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 40px;
      margin-top: 20px;
    }

    .home-Protect-feature {
      margin-bottom: 50px;

      h4 {
        color: #333333;
        font-family: "PT Sans";
        font-size: 1.1rem;
        font-weight: bold;
        letter-spacing: 0.25px;
        margin: 12px 0px;
      }

      p {
        margin: 0px 40px;
        font-weight: 300;
      }
    }
  }

  .main-review {
    @media (min-width: 576px) {
      margin: 40px;
    }

    @media (max-width: 575px) {
      margin: 20px 0px 0px 0px;
    }

    text-align: left;

    h3 {
      color: #77869B;
      font-family: "PT Sans";

      @media (min-width: 576px) {
        font-size: 38px;
      }

      @media (max-width: 575px) {
        font-size: 1.5rem;
      }
    }

    p {
      color: #333333;
      font-family: "PT Sans";
      font-size: 16px;
      line-height: 20px;
      margin: 20px 0px;
    }

    strong {
      color: #333333;
      font-family: "PT Sans";
      font-size: 16px;
      font-weight: bold;
    }

    h3, p, strong {
      @media (max-width: 575px) {
        margin-left: 20px;
        margin-right: 20px;
      }
    }
  }

  .reviews {
    @media (min-width: 576px) {
      margin: 40px;
    }

    @media (max-width: 575px) {
      margin: 20px 0px 0px 0px;
    }

    margin-top: 20px;

    h3 {
      font-family: "PT Sans";
      font-size: 24px;
      font-weight: bold;
      line-height: 32px;
      margin-bottom: 20px;
    }

    img {
      max-width: 50%;
    }

    @media (max-width: 575px) {
      img {
        max-width: 90%;
      }
    }

    p {
      max-width: 603px;
      color: #333333;
      font-family: "PT Sans";
      font-size: 16px;
      line-height: 20px;
      margin: 30px auto;
    }
  }

  .what-includes {
    @media (min-width: 576px) {
      margin: 40px;
    }

    @media (max-width: 575px) {
      margin: 20px 0px 0px 0px;
    }

    .image {
      padding: 0px;
    }

    .description {
      color: #FFFFFF;
      font-family: "PT Sans";
      background-color: #77869B;

      div {
        margin-top: 28%;
        padding-bottom: 28%;

        h3 {
          font-size: 24px;
          line-height: 32px;
          font-weight: 400;
        }

        p {
          font-size: 18px;
          margin: 10px;
        }
      }
    }
  }

  .entire-home-protection {
    padding: 40px;
    background-color: #F8FAFE;

    h3 {
      color: #77869B;
      font-family: "PT Sans";
      font-size: 1.3rem;
      letter-spacing: 1.25px;
      line-height: 23px;
    }

    .price {
      color: #333333;
      font-family: "PT Sans";
      font-size: 1.4rem;
      line-height: 26px;
      margin: 20px 0px 7px 0px;

      strong {
        font-size: 1.9rem;
      }
    }

    .installation-cost {
      color: #77869B;
      font-family: "PT Sans";
      font-size: 13px;
      line-height: 18px;
    }

    button {
      padding: 12px 20px;
      font-size: 1.2rem;
    }

    @media (max-width: 575px) {
      button {
        font-size: 1rem;
      }
    }

    .details {
      color: #77869B;
      font-family: "PT Sans";
      font-size: 15px;
      line-height: 19px;
      margin-top: 30px;

      span {
        margin-right: 25px;

        .fa-check {
          margin: 0px 10px;
          color: #1DD491;
        }
      }
    }
  }

  .saves-in {
    padding: 30px 0px 70px 0px;

    h4 {
      color: #333333;
      font-size: 16px;
      font-weight: bold;
      line-height: 19px;
    }

    p {
      max-width: 300px;
      color: #333333;
      font-family: "PT Sans";
      font-size: 16px;
      line-height: 20px;
      margin: 10px auto 0;
    }
  }

  .search-bar-container {
    .search-input,
    .search-input:focus,
    .search-input:active {
      @extend .form-control-lg;
    }
  }

  .fa-circle {
    color: darkgray;
    margin: 0px 10px;
    font-size: 0.7rem;
  }

  .fa-circle.active {
    color: black;
  }

  .footer {
    padding: 20px 0px 30px 0px;
    background-color: #19476D;

    .footer-block {
      color: #FFFFFF;
      font-family: "PT Sans";
      font-size: 16px;
      line-height: 20px;

      img {
        width: 65px;
      }

      .text {
        margin-top: 15px;
      }
    }
  }
}

.landing-header {
  background-color: white;
  border-bottom: 1px solid #d9d9d9;
  -webkit-box-shadow: 0 5px 6px -5px #d9d9d9;
  -moz-box-shadow: 0 5px 6px -5px #d9d9d9;
  box-shadow: 0 5px 6px -5px #d9d9d9;
}

.btn-landing {
  @extend .btn;

  padding: 12px 40px;
  border-radius: 4px;
  font-family: "PT Sans";
  font-size: 15px;
  min-width: 200px;
}

.btn-primary-landing {
  background-color: #1DD491;
  color: #FFFFFF;
}

.btn-secondary-landing {
  background-color: #fff;
  color: #5f5f5f;
  border: 1px solid #989898;
}

.btn-light-blue-landing {
  color: #fff;
  background-color: #10A3E8;
}

.modal {
  .btn-landing {
    margin: 0 auto;
  }
}

.account-creation-landing.user-creation {
  h1 {
    font-size: 24px;
    font-weight: 600;
    line-height: 30px;
    margin: 30px 0px 5px 0px;
  }

  h2 {
    color: #718094;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 1px;
    font-weight: 300;
  }

  small {
    color: #77869B;
    font-weight: 300;

    a {
      margin-left: 2px;
    }
  }

  .form {
    margin: 30px 0px 20px 0px;
  }

  .form-control {
    border-radius: 0rem;
    padding: 7px 17px;
  }

  .form-control::placeholder {
    color: #B8B8B8;
    font-family: "PT Sans";
    font-size: 15px;
    line-height: 19px;
    font-weight: 300;
  }

  .fa-check-circle {
    color: #1DD491;
  }

  .btn-landing {
    padding: 7px;
    font-weight: 300;
  }
}

.account-creation-landing.email-confirmation {
  h1 {
    font-size: 24px;
    font-weight: 600;
    line-height: 30px;
    margin: 30px 0px 10px 0px;
  }

  h2 {
    width: 610.5px;
    color: #77869B;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 1px;
    font-weight: 300;
    margin: 0 auto;
  }

  .envelope {
    width: 100px;
    margin-top: 20px;
  }

  .btn-landing {
    padding: 7px;
    font-weight: 300;
    margin: 30px auto 0px auto;
  }
}

.account-creation-landing.email-confirmed {
  h1 {
    font-size: 24px;
    font-weight: 600;
    line-height: 30px;
    margin: 30px 0px 5px 0px;
  }

  h2 {
    color: #77869B;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 1px;
    font-weight: 300;
    margin: 0 auto;
  }

  .fa-check-circle {
    color: #1DD491;
  }
}


