.login{
  height: 100%;

  .login-logo {
    margin-top: 80px;
    margin-bottom: 50px;
    max-width: 160px;
  }

  .floIcon {
    margin: 0 0 28px 0;
    .title {
      font-size: 3rem;
      font-weight: 100;
      color: $primaryDarkColor;
      display: inline-block;
      margin: 14px 0 0 16px;
    }
    img {
      float: left;
      margin: 4px 7px 0 0;
      max-width: 64px;
    }
  }

  .form {
    margin-bottom: 50px;

    .message-container {
      text-align:     left;

      .error-message {
        font-size:      1rem;
        text-align:     left;
        color:          $errorColor;
        font-weight:    600;
        letter-spacing: .05rem;
      }
    }

    input.invalid{
      color: $errorColor;
      border-color: $errorColor;
    }

    input.invalid::placeholder {
      color: $errorColor;
    }

    .flo-submit-btn.disabled{
      background: $disabledColor;
    }
  }
  &.password-reset .floIcon .title{
    font-size: 2.6rem;
  }
  .forgot-password{
    font-size:       1rem;
    text-transform:  uppercase;
    color:           $disabledColor;
    text-decoration: underline;
    cursor:          pointer;
    font-weight:     600;
    margin:          14px 0 0 0;
  }
  > #FloModal .message{
    padding: 16px;
    line-height: 2.2rem;
  }

  .btn-green {
    background-color: $secondaryDarkColor;
    border-color: $secondaryDarkColor;
  }

  .btn-green:hover {
    background-color: $secondaryDarkerColor;
    border-color: #498F33;
  }

  .btn-green.disabled{
    background: $disabledColor;
  }

  legend {
    border: none;
  }

  .text-danger {
    color: #E64759 !important;
  }

  .footer {
    word-break: break-word;
    overflow-wrap: break-word;
    position:relative;
    padding: 5px 0px 25px 0px;
  }
}

.tk-questrial > #app:has(.login) {
  margin-bottom: 0 !important;
}
