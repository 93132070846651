/*============================================================
PRICING STYLES
=============================================================*/
.btn-subscribe {
  border: 2px solid #979797;
  width: 75%;
}

@media (max-width: 575px) {
  .btn-subscribe {
    width: 90%;
  }
}

.btn-subscribe .fa-check {
  color: #4A90E2;
}

.btn-subscribe:hover {
  background-color: #D2D2D2;
  color: #000;
}

.btn-subscribe-pro {
  color: #fff;
  opacity: 1;
  background-color: $floProtectGreen;
  border: none;
}

.btn-subscribe-delinquent {
  color: #fff;
  opacity: 1;
  background-color: #FF9720;
  border: none;
}

.btn-subscribe-pro .fa-check {
  color: #fff;
}

.btn-subscribe-pro:hover {
  color: #fff;
  background-color: $floProtectDarkGreen;
}

.btn-subscribe-pro:disabled {
  opacity: 1 !important;
  background-color: #174266;
}

.pricing {
  margin-top: 50px;
  text-align: center;
  color: #000000;
  border: 1px solid #D2D2D2;
  background-color: white;
}

@media (min-width: 576px) {
  .pricing {
    margin-bottom: 30px;
  }
}

@media (max-width: 575px) {
  .pricing {
    margin-bottom: 0px;
  }
}

.pricing .plan-details {
  text-align: left;
  margin: 15px auto 0;
}

.pricing .plan-details ul {
  cursor: default !important;
  list-style: none;
  line-height: 30px;
  font-size: 13px;
  padding: 0px 7% 30px 7%;
  width: fit-content;
  margin: 0 auto;
}

.pricing .plan-details ul li a {
  cursor: pointer !important;
}

.pricing .plan-details ul li a i {
  margin-right: 5px;
}

.pro ul {
  font-weight: 600;
}

@media (max-width: 575px) {
  .pro ul {
    position: relative;
    margin-left: 4%!important;
  }

  .pro ul li {
    line-height: 20px;
    margin: 10px 0px;
  }

  .pro ul li:before {
    content: "+";
    position: absolute;
    left: 4px;
  }
}

.pricing .plan {
  padding: 20px;
  font-size: 1.6rem;
  color: #FFFFFF;
  border-bottom: 1px solid #EBEBEB;
}

.pricing .description {
  padding: 20px 20px;
  font-weight: bold;
  text-align: center;
}

.pricing .pricing-footer {
  padding: 20px;
}

.pricing .plan .icon-Circle-Other {
  color: #fff;
}

.attached > .col-lg-4,
.attached > .col-lg-3,
.attached > .col-md-4,
.attached > .col-md-3,
.attached > .col-sm-4,
.attached > .col-sm-3 {
  padding-left: 0;
  padding-right: 0;
}

.pricing.pro {
  z-index: 1;
  margin-top: 10px;
  box-shadow: 0px 4px 60px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.pricing.basic {
  color: #6D6D6D;
}

.pricing.basic .description {
  font-weight: normal;
}

.pricing .basic-plan {
  background-color: #F6FAFF;
  color: #19476D;
  font-weight: 400;
}

.pricing .pro-plan {
  background-color: #174266;
}

.pricing .pro-plan .fa-shield-alt {
  margin-top: 5px;
  margin-left: -15px;
  margin-right: 1%;
}

.home-protect .card-header {
  text-transform: capitalize; }

.home-protect .card-header:after {
  display: none;
}

@media (min-width: 576px) {
  .home-protect .card.home-protect-content {
    padding: 20px 20px 0px 20px;
  }
}

@media (max-width: 575px) {
  .home-protect .card.home-protect-content {
    padding: 0;
  }
}

.home-protect h1 {
  color: #333333;
  text-align: center;
}

.home-protect h2 {
  color: #333333;
  margin-bottom: 30px;
}

.home-protect h3 {
  color: #333333;
  font-weight: 300;
}

.home-protect .home-protect-content .wrapper .card-body {
  margin-left: 0px;
  margin-right: 0px;
}

.home-protect .short-description {
  margin: 10px;
  color: #333333;
  line-height: 22px;
}

.home-protect .drip-logo-container img {
  -webkit-transform: scale(0.6);
  /* Saf3.1+, Chrome */
  -moz-transform: scale(0.6);
  /* FF3.5+ */
  -ms-transform: scale(0.6);
  /* IE9 */
  -o-transform: scale(0.6);
  /* Opera 10.5+ */
  transform: scale(0.6);
}

.home-protect .answer {
  color: #777777;
  font-weight: 300;
  line-height: 22px;
  margin-bottom: 40px;
}

.home-protect .press-logos {
  padding: 20px 12%;
}

.home-protect .plans-features-table {
  margin-top: 40px;
  color: black;
}

.home-protect .plans-features-table button {
  width: 100%;
}

.home-protect .plans-features-table th {
  border: none;
  padding: 12px 10px;
}

.home-protect .plans-features-table th:nth-of-type(2) {
  font-weight: 500;
}

.home-protect .plans-features-table th:last-child {
  background-color: #1c486c;
  color: #fff;
}

.home-protect .plans-features-table tr:nth-of-type(odd) td {
  background-color: #FAFAFA;
}

.home-protect .plans-features-table tr:last-child td {
  background-color: #fff;
}

.home-protect .plans-features-table tr, .home-protect .table th {
  border: none;
}

.home-protect .plans-features-table td, .home-protect .plans-features-table th {
  border-right: 4px solid #fff;
  border-left: 4px solid #fff;
  border-top: none;
  border-bottom: none;
}

.home-protect .plans-features-table td:nth-child(2),
.home-protect .plans-features-table td:nth-child(3),
.home-protect .plans-features-table th:nth-child(2),
.home-protect .plans-features-table th:nth-child(3) {
  @extend .text-center;
}

.credit-card-payment {
  margin: 15px 15px 30px 15px;

  .current-credit-card {
    .cc-number {
      padding-left: 20px;
    }
    .cc-expiration {
      padding-left: 15px;
    }
  }
  .update-payment-link {
    margin-top: 16px;
  }
  .credit-card-info {
    font-size: 16px;
  }
}

.credit-card-payment label {
  font-weight: 400;
}

.credit-card-payment .row {
  margin-top: 20px;
  font-weight: 300;
}

.credit-card-payment .price {
  font-size: 1.2rem;
  padding: 7px;
  background-color: $floProtectGreen;
  color: #fff;
}

.credit-card-payment .plan-details ul {
  list-style-type: none;
  padding-left: 0px;
}

.credit-card-payment .plan-details ul li {
  font-weight: 300;
  display: flex;
}

.credit-card-payment .plan-details ul li .plan-icon {
  color: #9B9B9B;
  display: flex;
  flex-basis: 22px;
  margin-top: 2px;
}

.credit-card-payment .plan-details ul li .plan-text {
  display: flex;
  flex-grow: 1;
}

.credit-card-payment .btn-checkout {
  width: 100%;
  margin: 30px 0px 15px 0px;
}

.green {
  color: rgba(105, 220, 85, 0.64);
}

.pro-plan .pricing-title {
  vertical-align: middle;
  line-height: 50px;
}

.popover-icon {
  color: #6D6D6D;
  margin-left: 5px;
}

.pro .additional-info {
  text-align: left;
  font-weight: 300;
  padding: 0px 16% 0 9%;
  margin: 20px 0px 0px 0px;
}

.home-protect-banner {
  background-color: #F6FAFF;
}

.home-protect-banner img {
  margin-top: 40px;
  margin-bottom: 20px;
}

.home-protect-banner h3 {
  color: #333333;
  font-family: "PT Sans";
  font-weight: bold;
}

.home-protect-banner p {
  padding: 0% 11%;
  color: #333333;
  font-family: "PT Sans";
  line-height: 19px;
}

@media (min-width: 576px) {
  .home-protect-banner p {
    margin-bottom: 40px;
  }
}

@media (max-width: 575px) {
  .home-protect-banner p {
    margin-bottom: 0px;
  }

  .home-protect-banner .col-md-4:last-child {
    margin-bottom: 40px;
  }
}

/*============================================================
STRIPE ELEMENTS STYLES
=============================================================*/
.StripeElement {
  background-color: white;
  height: 40px;
  padding: 10px 12px;
  border-radius: 4px;
  border: 1px solid transparent;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

/*============================================================
PAYMENTS SUCCESS STYLES
=============================================================*/
/** Page-specific styles */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(1turn);
  }
}

@keyframes void-animation-out {
  0%,
  to {
    opacity: 1;
  }
}

.checkout.submitted .success,
.checkout.submitting .success {
  pointer-events: all;
}

.checkout.submitting .success .icon {
  opacity: 1;
}

.checkout.submitted .success > * {
  opacity: 1;
  transform: none !important;
}

.checkout.submitted .success > :nth-child(2) {
  transition-delay: 0.1s;
}

.checkout.submitted .success > :nth-child(3) {
  transition-delay: 0.2s;
}

.checkout.submitted .success > :nth-child(4) {
  transition-delay: 0.3s;
}

.checkout.submitted .success .icon .border,
.checkout.submitted .success .icon .checkmark {
  opacity: 1;
  stroke-dashoffset: 0 !important;
}

.checkout.submitted .success .lg-text {
  font-size: 16px;
}

.checkout.submitted .success .btn-subscribe-pro {
 margin-top: 18px;
}

.checkout form {
  position: relative;
  width: 100%;
  max-width: 500px;
  transition-property: opacity, transform;
  transition-duration: 0.35s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

.checkout.submitted .credit-card-payment,
.checkout.submitting .credit-card-payment {
  opacity: 0;
  transform: scale(0.9);
  pointer-events: none;
}

.checkout .success {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  padding: 10px;
  text-align: center;
  pointer-events: none;
  overflow: hidden;
}

.checkout .success > * {
  transition-property: opacity, transform;
  transition-duration: 0.35s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  opacity: 0;
  transform: translateY(50px);
}

.checkout .success .icon {
  margin: 15px 0 30px;
  transform: translateY(70px) scale(0.75);
}

.checkout .success .icon svg {
  will-change: transform;
}

.checkout .success .icon .border {
  stroke-dasharray: 251;
  stroke-dashoffset: 62.75;
  transform-origin: 50% 50%;
  transition: stroke-dashoffset 0.35s cubic-bezier(0.165, 0.84, 0.44, 1);
  animation: spin 1s linear infinite;
}

.checkout .success .icon .checkmark {
  stroke-dasharray: 60;
  stroke-dashoffset: 60;
  transition: stroke-dashoffset 0.35s cubic-bezier(0.165, 0.84, 0.44, 1) 0.35s;
}

.checkout .success .reset:active {
  transition-duration: 0.15s;
  transition-delay: 0s;
  opacity: 0.65;
}

.checkout .success .reset svg {
  will-change: transform;
}

.checkout .success .icon .border {
  stroke: #abe9d2;
}

.checkout .success .icon .checkmark {
  stroke: #24b47e;
}

.checkout .success .reset path {
  fill: #24b47e;
}

.floprotect-terms {
  color: #9e9e9e;
  font-weight: 300;
}

.cancel-subscription-modal {
  min-height: 462px;
}

.cancel-subscription {
  margin: 15px !important;

  .reason-form {
    .reason-title {
      font-size: 15px;
    }

    textarea {
      background-color: #F0F0F2;
      border-color: #F0F0F2;
    }

    .form-group {
      margin-bottom: 9px !important;
    }
  }

  .benefits-screen {
    color: #6D6D6D;
    ul {
      margin: 0;
      padding: 10px 0 0 15px;
    }

    ul.dashed {
      list-style-type: none;
    }

    ul.dashed > li {
      text-indent: -5px;
    }

    ul.dashed > li:before {
      content: "- ";
      text-indent: -5px;
    }

    span {
      font-size: 15px;
    }

    .footer-text {
      span {
        font-size: 14px;
      }      
    }

    .warning {
      font-size: 16px;
      color: #000;
    }
  }

  .btn-cancel-subscription {
    width: 47%;
    margin: 30px 0px 15px 0px;
    font-size: 16px;
  }

  .btn-continue {
    float: right;
  }

  .btn-back {
    border-color: #1DD491;
    color: #fff;
    opacity: 1;
    background-color: #1DD491;
  }
}

@media (min-width: 768px) {
  .home-protect .card.home-protect-content .order-md-12 {
    margin-left: -1px;
  }
}

.flo-protect-banner {
  margin: 40px 0;
  .row {
    margin-bottom: 30px;

    .image-wrapper {
      text-align: right;
      img { max-height: 70px; }
      border-right: 3px solid rgb(169, 190, 200);
    }

    .banner-details {
      color: rgb(11, 50, 86);
      h5 {
        font-size: 22px;
        font-weight: bold;
      }
      span {
        font-size: 16px;
      }
    }
  }
}

@media (min-width: 992px) {
  .flo-protect-banner {
    padding-left: 110px;
    padding-right: 110px;

    .image-wrapper {
      padding-right: 30px !important;
    }
    .banner-details {
      padding-left: 30px !important;
    }
  }
}
