/*
  Animation is here since we can't do this in JS with inline styles :(
*/
@keyframes skeletonAnimation {
  0% {
    opacity: 0.8;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 0.8;
  }
}

.pending-div {
  color: grey !important;
  background-color: grey !important;
  animation-name: skeletonAnimation;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
