.detector-telemetry-stats {
  margin: 32px 140px;

  .detector-telemetry-widget {

    .detector-icon {
      height: 116px;
    }

    .icon-battery_12 {
      background: url("/assets/images/battery_icon_12.png");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 70px;
    }

    .icon-battery_25 {
      background: url("/assets/images/battery_icon_25.png");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 70px;
    }

    .icon-battery_50 {
      background: url("/assets/images/battery_icon_50.png");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 70px;
    }

    .icon-battery_75 {
      background: url("/assets/images/battery_icon_75.png");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 70px;
    }

    .icon-battery_100 {
      background: url("/assets/images/battery_icon_100.png");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 70px;
    }

    .icon-humidity {
      background: url("/assets/images/humidity_icon.png");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 70px;
    }
    .icon-temperature {
      background: url("/assets/images/temperature_icon.png");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 70px;
    }

    .detector-name {
      font-size: 14px;
    }

    .detector-value {
      font-size: 30px;
    }

    @include media-breakpoint-up(lg) {
      .detector-name {
        font-size: 20px;
      }

      .detector-value {
        font-size: 40px;
      }
    }

    @include media-breakpoint-down(xs) {
      .detector-name {
        font-size: 20px;
      }

      .detector-value {
        font-size: 40px;
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .detector-telemetry-stats {
    margin: 32px 0px;
  }
}

@include media-breakpoint-up(sm) {
  .row.vertical-divider {
    overflow: hidden;
  }
  .row.vertical-divider > div[class^="col-"] {
    text-align: center;
    padding-bottom: 100px;
    margin-bottom: -100px;
    border-left: 1px solid #E2E2E2;
    border-right: 1px solid #E2E2E2;
  }
  .row.vertical-divider div[class^="col-"]:first-child {
    border-left: none;
  }
  .row.vertical-divider div[class^="col-"]:last-child {
    border-right: none;
  }
}
