
.dialog-wrapper{
  position:    absolute;
  top:         0;
  left:        0;
  width:       100%;
  height:      100%;
  background:  rgba(0,0,0,0.25);
  text-align:  center;
  line-height: 100vh;
  z-index:     2;
  padding: 0px 20px;

  .dialog{
    min-width:   200px;
    max-width:   500px;
    background:  white;
    margin:      0 auto;
    display:     inline-block;
    line-height: normal;
    .message{
      font-size:      1.2rem;
      border:         3px solid $primaryLightColor;
      padding:        16px 16px 10px 0;
      vertical-align: middle;
      & > span{
        display: block;
        text-align: center;
        width: 100%;
      }
      img{
        height: 125px;
      }
      .message-block{
        display: inline-block;
        max-width: 400px;
        margin-top: 12px;
      }
    }
  }
  button{
    display:        inline-block;
    max-width:      100%;
    min-width:      50%;
    border:         3px solid $primaryLightColor;
    margin-top:     -3px;
    background:     white;
    color:          $primaryLightColor;
    line-height:    2rem;
    font-weight:    600;
    padding-top:    6px;
    text-transform: uppercase;

  }
  &.four-buttons{
    button{
      min-width: 25%;
    }
  }
  &.ok button{
    width: 100%;
  }
  &.info{
    .dialog .message{
      border-color: $primaryLightColor;
    }
    button{
      color: $primaryLightColor;
      border-color: $primaryLightColor;
    }
    .slide-select{ display: none; }
  }
  &.warning{
    .dialog .message{
      border-color: $warningColor;
      &:before{
        content:        "!";
        width:          36px;
        height:         36px;
        border-radius:  18px;
        border:         2px solid $warningColor;
        display:        inline-block;
        color:          $warningColor;
        text-align:     center;
        font-size:      2rem;
        line-height:    30px;
        font-weight:    600;
        margin:         4px 16px 4px 16px;
        padding:        0 0 0 1px;
        vertical-align: top;
      }
    }
    button{
      color: $warningColor;
      border-color: $warningColor;
    }
  }
	&.error, &.critical{
		.dialog .message{
      border-color: $errorColor;
      padding: 16px 16px 10px 16px;
    }
    button{
      color: $errorColor;
      border-color: $errorColor;
    }
	}
	.input-wrapper{
		width: 100px;
		margin: 0 auto;
		input {
			width:         100%;
			padding:       10px 0 4px 0;
			outline:       none;
			border:        none;
			border-bottom: thin solid $primaryLightColor;
			font-size:     1.8rem;
		}
		label {
			position:    absolute;
			cursor:      text;
			color:       $primaryLightColor;
			font-weight: normal;
			font-size:   1.8rem;
			transition:  0.3s ease-out;
			background:  transparent;
		}
		.label-input{
			position: relative;
			margin:   10px 0 8px 0;
		}
		.label {
			left:         0;
			top:          -8px;
			font-size:    1.4rem;
			color:        $primaryLightColor;
			transition:   0.3s ease-out;
			font-weight:  normal;
			padding-left: 0;
		}
		.placeholder {
			left:        0;
			top:         12px;
			transition:  0.3s ease-out;
			font-weight: normal;
		}
	}
  .inner-wrapper{
    display:    inline-block;
    max-width:  400px;
    text-align: left;
    margin-left: 16px;

    .title{
      padding-top: 0;
      text-align:  left;
      color:       $primaryDarkColor;
    }
    .message-block{
      text-align: left;
      font-size: 1.4rem;
    }
    .slide-select{
      margin-top: 8px;
      .controlName{
        margin-left: 0;
      }
    }
    &.qr-code{
      text-align: center;
      svg{
        width: 120px;
      }
    }
  }
  &.alarm-dialog{
    &.warning .dialog{
      background: $warningDarkColor;
      .buttons a{
        color: $warningDarkColor;
        i:before{
          color: $warningDarkColor;
        }
      }
    }
    &.critical .dialog{
      background: $errorDarkColor;
      .buttons a{
        color: $errorDarkColor;
        i:before{
          color: $errorDarkColor;
        }
      }
    }
    .dialog{
      text-align: center;
      padding:    20px 50px;
      max-width:  400px;
      position:   relative;
      .close {
        position:    absolute;
        top:         6px;
        right:       10px;
        cursor:      pointer;
        opacity:     1;
        text-shadow: none;
        width:       18px;
        height:      22px;
        i{
          &:before{
            color:     white;
            font-size: 1.4rem;
          }
        }
      }
      .alarm-bell{
        text-align: center;
        &:before{
          font-family: FloIcons;
          content:     "\e900";
          color:       white;
          font-size:   3rem;
        }
      }
      .title{
        text-align: center;
        font-size:  2rem;
        color:      white;
      }
      .message{
        border:     none;
        text-align: center;
        color:      white;
        font-size:  1.4rem;
        padding:    0;
        margin:     0 0 16px  0;
        &:before{
          content: none;
        }
      }
      .buttons a{
        background:    white;
        display:       inline-block;
        width:         100%;
        padding:       8px 14px;
        color:         black;
        border-radius: 6px;
        font-size:     1.4rem;
        margin:        4px 0;
        font-weight:   600;
        cursor:        pointer;
        text-decoration: none;
        i{
          float: right;
          &:before{
            font-size: 1.8rem;
          }
          &.icon-Info{
            margin-right: 10px;
          }
          &.icon-Check{
            margin-right: -3px;
          }
        }
      }
    }
  }
}
