.insurance-letter {
  .btn-primary, .btn-primary:disabled, .btn-primary.disabled  {
    background-color: $floProtectGreen;
    border: none;
  }

  .btn-primary:hover {
    background-color: $floProtectDarkGreen;
  }

  .btn-primary:active, .btn-primary:focus {
    background-color: $floProtectDarkerGreen!important;
  }

  .non-sub.letter {
    .banner {
      opacity: 0.85;
      background-color: $flo-blue;
      color: white;

      a {
        color: $floProtectGreen;

      }

    }
  }
}
