.telemetry-widget {
  cursor:         default;

  .context {
    font-weight: 600;
  }

  .unit {
    padding: 0 0 0 2px;
  }

  .context, .value {
    text-align:  center;
    line-height: 1.2rem;
  }

  @include media-breakpoint-up(sm) {
    .wrapper {
      display: inline-block;
      margin-left: 20px;
    }

    .context, .value {
      text-align:  left;
      line-height: 1.2rem;
    }
  }
}

.telemetry-widget::before {
  color:     $flo-green;
  font-size: 2.2rem;
}

.no-device .telemetry-widget {
  color: $disabledColor;
  &:before, .context {
    color: $disabledColor;
  }
}
