.flo-group-togglebox {
  margin-top: 4px;
  height: 1rem;

  .depressed-box.checkbox {
    border-radius: 25%;
    border: 1px solid #bfbfbf;
    box-shadow:    inset 0px 1px 0px 0px rgba(0, 0, 0, 0.1);
    background:    #fff;
    cursor:        pointer;
    width:         0.9rem;
    height:        0.9rem;
    margin: 0 auto;
  }

  .depressed-box {
    padding-top: 1px;

    &[data-active="all"], &[data-active="partial"]{
      background: #419bf0;
      border-color: #419bf0;
      box-shadow: none;

      &:before {
        color:       white;
        font-size: 0.6rem;
        padding: 1px 0 0 1px;
        display:     block;
      }
    }
    &.light[data-active="all"],
    &.light[data-active="partial"]{
      background: #419bf0;
      box-shadow: none;
    }
  }

  &.disabled .depressed-box {
    border: 1px solid #c9c9c9;

    &[data-active="all"], &[data-active="partial"]{
      background: #fff;
      border: 1px solid #c9c9c9;
      box-shadow: inset 0px 1px 0px 0px rgba(0, 0, 0, 0.05);

      &:before {
        color: #7b7b7b;
      }
    }
    &.light[data-active="all"],
    &.light[data-active="partial"]{
      background: #fff;
      box-shadow: inset 0px 1px 0px 0px rgba(0, 0, 0, 0.05);
    }
  }
}

.icon-IndeterminateCheckBox::before {
  height: 2px !important;
  width: 0.6rem !important;
  background-color: white;
  margin-left: 0.1rem;
  margin-top: 0.2rem;
  content: "";
}

