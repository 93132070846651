.white-logo {
  padding: 5px 0px;
}

.bigger-logo {
  height: 45px;
  margin: 0px 6px 0px 6px;
}

.big-logo {
  height: 35px;
  margin: 0px 6px 0px 6px;
}

.medium-logo {
  height: 25px;
  margin: 0px 5px 0px 5px;
}

.small-logo {
  height: 20px;
  margin: -1px 4px 0px 4px;
}
