.search-bar-component {
  width: 100%;

  .error-message {
    position: absolute!important;
    z-index: 10;
    overflow: hidden;
  }
}

.search-bar-container {
  .search-input-container {
    position: relative;
  }

  .search-input,
  .search-input:focus,
  .search-input:active {
    box-shadow: 0 2px 2px 0 rgba(0,0,0,0.16), 0 0 0 1px rgba(0,0,0,0.08);
    border: honeydew;
    display: block;
    width: 100%;
    padding: 16px;
    font-size: 16px;
    border-radius: 2px;
    outline: none;
  }

  .clear-button,
  .clear-button:active,
  .clear-button:focus {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    background: transparent;
    border: none;
    outline: none;
    font-weight: 600;
    color: #999;
  }

  .autocomplete-container {
    border-bottom: honeydew;
    border-left: honeydew;
    border-right: honeydew;
    border-top: 1px solid #e6e6e6;
    box-shadow: 0 2px 4px rgba(0,0,0,0.2);
    border-radius: 0 0 2px 2px;

    @include media-breakpoint-only(xs) { min-width: 75.8%; }
    @include media-breakpoint-only(sm) { min-width: 60.3%; }
    @include media-breakpoint-only(md) { min-width: 60.3%; }
    @include media-breakpoint-only(lg) { min-width: 32.3%; }
    @include media-breakpoint-only(xl) { min-width: 30.3%;  }

    /* Position the suggestions after the search input but floating */
    position: absolute!important;
    z-index: 1000;
    overflow: hidden
  }

  .suggestion-item {
    padding: 8px;
    text-align: left;
    background-color: #fff;
    cursor: pointer;
  }

  .suggestion-item--active {
    background-color: #fafafa;
  }

  .dropdown-footer {
    display: flex;
    justify-content: flex-end;
    background-color: #fff;
    border-top: 1px solid #e9e9e9;
    padding: 4px;
  }

  .dropdown-footer-image {
    display: inline-block;
    width: 150px;
  }

  .error-message {
    color: red;
  }
}
