.settings-notifications {

  tr.title-row {
    cursor: pointer;
    border-width: 3px;
    border-bottom: 2px solid;
    text-transform: uppercase;

    &.critical{
      border-color: $errorColor;
      color: $errorColor;
    }
    &.warning{
      border-color: $warningColor;
      color: $warningColor;
    }
    &.info{
      border-color: $successColor;
      color: $successColor;
    }
    td {
      &:first-child:before{
        content: ">";
        display: inline-block;
        transition: all .1s ease-out;
        margin-right: 10px;
      }
    }
    &.open td:first-child:before{
      transform: rotate( 90deg );
    }
  }

  td {
    text-transform: uppercase;
    padding: 6px 10px;
    &.alarm-name {
      font-weight: 600;
      padding: 6px 0 6px 20px;
    }
    &.water-shutoff {
      padding: 6px 0 6px 40px !important;
    }
    &:first-child {
      padding: 6px 10px 6px 18px;
    }
  }

  .card {
    .card-header {
      .title {
        float: left;
        margin-top: 6px;
      }
      .device-dropdown {
        float: left;
        margin-left: 12px;
      }
    }
  }

  .card-header:after {
    margin-top: 3.3rem;
  }
}


.no-device{
  .settings-notifications .wrapper tr.title-row {
    cursor: default;
  }
  .flo-group-togglebox .depressed-box{
    background: $disabledColor!important;
    cursor:     default;
  }
}
