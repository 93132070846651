.pagination{
	margin:         0 auto;
	font-weight:    600;
  text-transform: uppercase;

	.previous, .next, .first {
		cursor: pointer;
	}

  .previous {
    margin-right: 20px;
  }

  .show-page {
    font-weight: 500;
  }
}
