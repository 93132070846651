$primaryDarkColor:        #164063;
$primaryUltraDarkColor:   #103657;
$primaryLightColor:       #4292BE;
$primaryUltraLightColor:  #8dc9eb;
$secondaryLightColor: rgba(105, 220, 85, 0.64);
$secondaryDarkColor:      #70d549;
$secondaryDarkerColor: #56a339;
$tertiaryColor:           #FE9B39;

$primaryBackgroundColor: #E7E7E7;

$disabledColor:        #797979;
$disabledDarkerColor:  darken( $disabledColor, 10% );
$disabledLighterColor: #CCCCCC;

$successColor:      $secondaryLightColor;
$warningColor:      #FFB142;
$warningDarkColor:  #e6922a;
$errorColor:        #D73333;
$errorDarkColor:    #970404;


$floProtectGreen: #1DD491;
$floProtectDarkGreen: #16AA74;
$floProtectDarkerGreen: #148e5d;
