.footer {
  bottom: 0;
  width: 100%;
  position: absolute;
  padding-bottom: 20px;
  background-color: #235a7f;

  @include media-breakpoint-down(sm) {
    padding-bottom: 68px;
  }

  .footer-block {
    margin-top: 20px;
  }
}

.companyDisclosureLinks {
  a {
    text-decoration: none;
    color:           #fff;
    line-height:     100%;
    &:after{
      content:     "∙";
      margin-left: 6px;
      margin-right:    6px;
    }
  }
  span:last-child a:after{
    content: "";
    display: none;
  }
}

.contactBlock {
  color: white;

  @include media-breakpoint-down(sm) {
    margin-bottom: 40px;
  }

  @include media-breakpoint-up(lg) {
    margin-right: 120px;
  }

  .icon {
    &:before{
      margin-right: 5px;
    }
  }

  .content a {
    text-decoration: none;
    color: white;
    font-size: 1rem;
  }
}
