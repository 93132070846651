.alerts-log{
  .icon-Bell:before {
    margin-right: 5px;
  }

  .critical-alerts-subtitle, .warning-alerts-subtitle{
    text-transform: uppercase;
    & + hr {
      margin: -2px 0 0;
      border-top-width: 2px;
    }
  }
  .critical-alerts-subtitle{
    color: $errorColor;
    & + hr {
      background: $errorColor;
    }
  }
  .warning-alerts-subtitle{
    color: $warningColor;
		& + hr {
      background: $warningColor;
    }
  }
  .table-list{
    tbody tr{
      cursor: pointer;
      td {
        &:first-child{
          text-transform: initial;
        }
        &.system_mode_text, &.severity{
          text-transform: capitalize;
        }
      }
    }
    &.warning-alerts{
      thead th{
        color: $warningColor;
      }
    }
    &.critical-alerts{
      thead th{
        color: $errorColor;
      }
    }
  }
  .clear-alerts{
    text-transform:  uppercase;
    text-decoration: underline;
    color:           $disabledColor;
    cursor:          pointer;
    font-weight:     600;
    display:         inline-block;
    margin-top:      1.6rem;
  }
  .warning-alerts{
    .column-head{
      color: $warningColor;
    }
  }
  .critical-alerts{
    .column-head{
      color: $errorColor;
    }
  }

  .column{
    &.message{
      color:       $primaryDarkColor;
      font-weight: normal;
    }
    &.type{
      max-width: 80px;
    }
    &.severity{
      &.warning{
        color: $warningColor;
      }
      &.critical{
        color: $errorColor;
      }
    }
    &.datetime{
      font-weight: normal;
    }
  }
}
