.location-selector {
  margin-top: 15px;
  min-height: 34px;

  .location-dropdown {
    float: left;
    min-width: 300px;

    .dropdown-toggle {
      background-color: transparent !important;
      border: none !important;
      font-size: 18px;
      color: #000;
      outline: none;
      outline-color: transparent;
    }
  }

  .system-mode {
    float: left;
    margin-left: 30px;
    padding-top: 5px;
  }

  @include media-breakpoint-down(sm) {
    .location-dropdown {
      float: none;
      text-align: center;
    }
    .system-mode {
      float: none;
      margin: 10px 0 10px 0;
      text-align: center;
    }
  }

  .single-location {
    font-size: 16px;
    padding-top: 7px;
  }

  .loader-wrapper {
    display: flex;
    width: 20px;
    padding-top: 10px;
    margin-left: 60px;

    .loader {
      border: 3px solid #f3f3f3;
      border-radius: 50%;
      border-top: 3px solid #3498db;
      width: 20px;
      height: 20px;
      margin: auto;
      -webkit-animation: spin 2s linear infinite; /* Safari */
      animation: spin 2s linear infinite;
    }
  }
}