@font-face {
  font-family: "FloIcons";
  src:  url('/assets/fonts/FloIcons.eot');
  src:  url('/assets/fonts/FloIcons.eot#iefix') format('embedded-opentype'),
    url('/assets/fonts/FloIcons.ttf') format('truetype'),
    url('/assets/fonts/FloIcons.woff') format("woff");
  font-weight: normal;
  font-style: normal;
}
  
[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change      fonts */
  &:before, &:after{
    font-family: 'FloIcons' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

.icon-Bell:before {
  content: "\e900";
}
.icon-Check:before {
  content: "\e920";
  color: #d13c38;
}
.icon-Checkmark:before {
  content: "\e901";
}
.icon-Circle-Shower:before {
  content: "\e902";
}
.icon-Circle-Dishwasher:before {
  content: "\e903";
}
.icon-Circle-Faucet:before {
  content: "\e904";
}
.icon-Circle-Other:before {
  content: "\e905";
}
.icon-Circle-Toilet:before {
  content: "\e906";
}
.icon-Circle-Laundry:before {
  content: "\e907";
}
.icon-Closing-X:before {
  content: "\e908";
}
.icon-Envelope:before {
  content: "\e909";
}
.icon-Faucet:before {
  content: "\e90a";
}
.icon-Gear:before {
  content: "\e90f";
  color: #d13c38;
}
.icon-Info:before {
  content: "\e90e";
  color: #d13c38;
}
.icon-Phone:before {
  content: "\e90b";
}
.icon-PSI-Gauge:before {
  content: "\e90c";
}
.icon-Thermometer:before {
  content: "\e90d";
}
.icon-download-locked {
  font-size: 14px;
}
