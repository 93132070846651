@import "FloIcons.scss";


@supports (font: -apple-system-body) {
  .login, 
  .login .contactBlock .content a,
  .login form input,
  .login legend,
  .login form button,
  .login .forgot-password,
  .login .form .message-container .error-message {
    font: -apple-system-body !important;
  }
}