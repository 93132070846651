.account-confirmation {
  h1 {
    font-size: 24px;
    font-weight: 600;
    line-height: 30px;
    margin: 30px 0px 40px 0px;
  }

  h2 {
    color: #77869B;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 1px;
    font-weight: 300;
    margin: 0 auto;
  }

  .flo-logo {
    margin-top: 18px;
    max-width: 68px;
    margin-left: 17px;
  }

  .envelope {
    width: 100px;
    margin-top: 20px;
  }

  .btn-landing {
    padding: 7px;
    font-weight: 300;
    margin: 30px auto 0px auto;
  }

  .confirmation-message {
    margin-top: 55px;

    .title-error {
      color: #ff0000c7;
    }
    .title-success {
      color: #60D096;
    }
  }

  .loader-wrapper {
    min-height: 190px;
    padding-top: 120px;

    .loader {
      border: 5px solid #f3f3f3;
      border-radius: 50%;
      border-top: 5px solid #3498db;
      width: 80px;
      height: 80px;
      margin: auto;
      -webkit-animation: spin 2s linear infinite; /* Safari */
      animation: spin 2s linear infinite;
    }
  }

  .main-container {
    padding-bottom: 100px;
  }

}